import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
//import AppliedRoute from "./components/Routes/AppliedRoute";
import AuthenticatedRoute from "./components/Routes/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/Routes/UnauthenticatedRoute";

import Tickets from "./containers/Tickets/Tickets";
import Ticket from "./containers/Tickets/Ticket";
import CreateTicket from "./containers/Tickets/Create";
import UpdateTicket from "./containers/Tickets/Update";
import AcceptTicket from "./containers/Tickets/Accept";
import TicketReports from "./containers/Tickets/Reports";

import Invoices from "./containers/Invoices/Invoices";
import Invoice from "./containers/Invoices/Invoice";
import UpdateInvoice from "./containers/Invoices/Update";
import CreateInvoice from "./containers/Invoices/Create";
import GenerateSummary from "./containers/Invoices/Summary";

import Customers from "./containers/Customers/Customers";
import View from "./containers/Customers/View";
import UpdateCustomer from "./containers/Customers/Update";
import CreateCustomer from "./containers/Customers/Create";

import Users from "./containers/Users/Users";
import User from "./containers/Users/User";
import CreateUser from "./containers/Users/Create";
import UpdateUser from "./containers/Users/Update";
import Profile from "./containers/Users/Profile";

import Reports from "./containers/Reports/Reports";

import NotFound from "./containers/NotFound/NotFound";

import Login from "./containers/Login/Login";
import Forgot from "./containers/Login/Forgot";


export default function Routes({ appProps }) {
  return (
    <Switch>
      <Route exact path="/"> <Redirect to="/tickets" /> </Route>
      <AuthenticatedRoute path="/tickets" exact component={Tickets} appProps={appProps} />
      <AuthenticatedRoute path="/ticket" exact component={Ticket} appProps={appProps} />
      <AuthenticatedRoute path="/create_ticket" exact component={CreateTicket} appProps={appProps} />
      <AuthenticatedRoute path="/update_ticket" exact component={UpdateTicket} appProps={appProps} />
      <AuthenticatedRoute path="/accept_ticket" exact component={AcceptTicket} appProps={appProps} />
      <AuthenticatedRoute path="/ticket_report" exact component={TicketReports} appProps={appProps} />

      <AuthenticatedRoute path="/customers" exact component={Customers} appProps={appProps} />
      <AuthenticatedRoute path="/customer" exact component={View} appProps={appProps} />
      <AuthenticatedRoute path="/update_customer" exact component={UpdateCustomer} appProps={appProps} />
      <AuthenticatedRoute path="/create_customer" exact component={CreateCustomer} appProps={appProps} />

      <AuthenticatedRoute path="/users" exact component={Users} appProps={appProps} />
      <AuthenticatedRoute path="/user" exact component={User} appProps={appProps} />
      <AuthenticatedRoute path="/update_user" exact component={UpdateUser} appProps={appProps} />
      <AuthenticatedRoute path="/create_user" exact component={CreateUser} appProps={appProps} />
      <AuthenticatedRoute path="/profile" exact component={Profile} appProps={appProps} />

      <AuthenticatedRoute path="/invoices" exact component={Invoices} appProps={appProps} />
      <AuthenticatedRoute path="/invoice" exact component={Invoice} appProps={appProps} />
      <AuthenticatedRoute path="/create_invoice" exact component={CreateInvoice} appProps={appProps} />
      <AuthenticatedRoute path="/update_invoice" exact component={UpdateInvoice} appProps={appProps} />

      <AuthenticatedRoute path="/generate_summary" exact component={GenerateSummary} appProps={appProps} />

      <AuthenticatedRoute path="/reports" exact component={Reports} appProps={appProps} />

      <UnauthenticatedRoute path="/login" exact component={Login} appProps={appProps} />
      <UnauthenticatedRoute path="/forgot" exact component={Forgot} appProps={appProps} />

      {/* Finally, catch all unmatched routes */}
      <Route component={NotFound} />
    </Switch>
  );
}
