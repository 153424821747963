import React, { useState, useContext, useEffect } from "react";
import { checkEmail } from "../../libs/Errors";
import { API } from "aws-amplify";
import { userContext } from "../../context/User";
import { customersContext } from "../../context/Customers";

import {
  Grid, Paper, Typography, Button,
  FormControl, MenuItem, Select,
  InputLabel, TextField
} from "@material-ui/core";
import AutoComplete from '@material-ui/lab/Autocomplete'
import { Clear, Add } from "@material-ui/icons";


export default function UserForm({ newUser, setNewUser }) {

  const user = useContext(userContext);
  const customers = useContext(customersContext);

  const [emailError, setEmailError] = useState(false);
  const [customer, setCustomer] = useState({});
  const [customerNum, setCustomerNum] = useState(0);
  const [customerNumErr, setCustomerNumErr] = useState("");

  useEffect(() => {
    if (!user || !customers) return;
  }, [user, customers]);

  function emailExists(email) {

    const params = { queryStringParameters: { user_id: user.user_id } }
    API.get("api", `/validate_email?email=${email}`, params).then((resp) => {
      if (resp === true) setEmailError("Email already exists");
      return resp;
    });
  }

  return (
    <Grid container spacing={5}>
      <Grid container item xs={12} style={{ justifyContent: "space-between" }} spacing={1}>
        <Grid item xs={12}><Typography style={{ fontWeight: 600, paddingBottom: "10px" }}> User Information: </Typography></Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            fullWidth
            label="Name"
            value={newUser.name}
            onChange={(e) => {
              setNewUser({ ...newUser, name: e.target.value })
            }}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            fullWidth
            label="Phone"
            value={newUser.phone}
            onChange={(e) => {
              setNewUser({ ...newUser, phone: e.target.value })
            }}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            fullWidth
            label="Email"
            value={newUser.email}
            helperText={emailError}
            onChange={(e) => {
              setNewUser({ ...newUser, email: e.target.value })
              if (e.target.value !== "" && checkEmail(e.target.value)) {
                setEmailError("Invalid email");
              } else if (emailExists(e.target.value)) {
              } else {
                setEmailError("");
              }
            }}
            error={checkEmail(newUser.email)}
          />
        </Grid>

        <Grid item sm={3} xs={12}>
          <FormControl fullWidth >
            <InputLabel id='role-label'>Role</InputLabel>
            <Select
              labelId="role-label"
              style={{ height: "100%", width: "100%" }}
              value={newUser.role}
              onChange={(e) => setNewUser({ ...newUser, role: e.target.value })}
            >
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="customer">Customer</MenuItem>
              <MenuItem value="hauler">Hauler</MenuItem>
            </Select>
          </FormControl>
        </Grid>

      </Grid>
      {/*- Top Row -*/}


      {newUser.role === "customer" && (
        <Grid item container xs={12} style={{ justifyContent: "space-between" }}>
          <Grid item xs={12}><Typography style={{ fontWeight: 600 }}> Add Accounts: </Typography></Grid>
          <Grid item sm={2} xs={12}>
            <TextField // admins get a text field where you can enter the Account number
              required
              error={customerNumErr}
              style={{ paddingTop: "12px" }} //space-between not working, so make item fullwidth and shrink
              value={customerNum}
              onChange={async (e) => {
                setCustomerNum(e.target.value)
                const params = { queryStringParameters: { user_id: user.user_id } }
                //check db for existence of customer with this number
                const resp = await API.get("api", "/customers/" + e.target.value.padStart(4, '0'), params);
                if (!resp) {
                  setCustomerNumErr(true);
                  return
                }
                setCustomerNumErr(false);
                setCustomer(resp);
              }}
              helperText={customerNumErr ? "Invalid Acct#" : "Acct#"}
              inputProps={{ style: { fontSize: '1.15rem' } }} // font size of input text
              InputLabelProps={{ style: { fontSize: '1.15rem' } }} // font size of input label
            />
          </Grid>

          <Grid item sm={5} xs={12}>
            <AutoComplete
              options={Object.values(customers)}
              getOptionLabel={(option) => option.name ? option.name : ""}
              value={customer || null}
              renderInput={(params) => <TextField style={{ width: "100%" }}{...params} label="Account Name" />}
              onChange={(event, customer) => {
                if (customer) {
                  setCustomerNum(customer.customer_number);
                  setCustomerNumErr(false);
                  setCustomer(customer);
                }
              }}
            />
          </Grid>

          <Grid item sm={1} xs={12}>
            <Button
              style={{ height: "100%" }}
              startIcon={<Add style={{ marginLeft: "10px" }} />}
              variant="contained"
              color="primary"
              onClick={() => {
                if (customerNumErr !== false) return;
                if (customerNum === 0) return;
                if (!newUser.accounts.includes(customer.customer_id)) {
                  setNewUser({
                    ...newUser,
                    accounts: [...newUser.accounts, customer.customer_id]
                  })
                }
                setCustomer({});
                setCustomerNum(0);
              }}
            />
          </Grid>
        </Grid>
      )}

      {newUser.accounts.length > 0 && (
        <Grid item container xs={12}>
          <Typography style={{ paddingBottom: "20px", fontWeight: 600 }}>Account List:</Typography>
          {
            newUser.accounts.map((acct) => {
              return (
                <Grid style={{ marginBottom: "10px" }} container item xs={12}>
                  <Grid item xs={3}>
                    <Typography>{customers[acct].customer_number}</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography>{customers[acct].name}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      style={{ height: "100%" }}
                      startIcon={<Clear style={{ marginLeft: "10px" }} />}
                      variant="contained"
                      onClick={() => {
                        setNewUser({
                          ...newUser,
                          accounts: newUser.accounts.filter((item) => item !== acct)
                        })
                      }}
                    />
                  </Grid>

                </Grid>
              );
            })
          }
        </Grid>
      )}

    </Grid>
  );
}
