import React, { useState, useEffect, useContext } from "react";
import {
  Grid, Paper, Typography, Button, CircularProgress,
} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { ArrowBackIos as BackIcon, } from "@material-ui/icons";
import { API } from "aws-amplify";

import moment from 'moment';

// components
import Loader from "../../components/Loader/Loader";

// libs
import { querystring } from "../../libs/querystring"
import { userContext } from "../../context/User";

// styles
import useStyles from "./styles";

export default function Accept(props) {
  const classes = useStyles();
  const user = useContext(userContext);

  //state
  const [ticket, setTicket] = useState(null);

  // ticket to be submitted
  const [ETA, setETA] = useState(null);
  const [updateErr, setUpdateErr] = useState('');
  const [ETAErr, setETAErr] = useState(false);
  const [notes, setNotes] = useState([]);
  const [note, setNote] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (!user) return;

    // get ticket id from url
    const ticket_id = querystring('ticket_id');
    const params = { queryStringParameters: { user_id: user.user_id } }

    // fetch ticket from ddb
    API.get('api', 'tickets/' + ticket_id, params).then(resp => {
      setTicket(resp);
      setNotes(resp.notes);
      setETA(resp.eta ? new Date(resp.eta) : null);
    }).catch(() => console.log('here')); // TODO: handle error
  }, [user]);


  async function handleAccept() {

    let updates = "";

    let updated = {
      "status": "Pending",
      "date": new Date(Date.now()).toISOString(),
      "hauler_id": user.user_id,
    };

    updates += "Driver accepted - Status changed to Pending. ";

    // validate ETA
    if (!ETA || (ETA && ETA.toString() === 'Invalid Date')) {
      setETAErr(true);
      return;
    }
    if (ETA) {
      updates += "ETA changed to " + moment(ETA).format('MM-DD-YYYY') + ". ";
    }
    updated.eta = ETA;

    // note
    if (!updates && !note) {
      setUpdateErr('No changes to submit.');
      setIsLoading(false);
      return;
    }
    const noteArr = notes;
    const date = new Date();
    noteArr.push({ [date.toISOString() + user.role]: updates + note });
    updated.notes = noteArr;

    // update ticket
    API.post("api", "update_ticket/" + ticket.ticket_id, { body: updated }).then(() => {
      props.history.push('/tickets')
    });
  }

  return (
    ticket && user ? (
      <Grid container className={classes.container}>
        <Paper classes={{ root: classes.paperRoot }}>
          <React.Fragment>
            <Typography variant="h3" gutterBottom>
              Accept Pickup Request
            </Typography>

            <Grid container spacing={2} style={{ justifyContent: "space-between", marginTop: '10px' }}>

              <Grid item xs={10} sm={5}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    error={ETAErr}
                    disableToolbar
                    variant="inline"
                    format="MM-dd-yyyy"
                    label="ETA"
                    inputProps={{ style: { fontSize: '1.15rem' } }} // font size of input text
                    InputLabelProps={{ style: { fontSize: '1.15rem' } }} // font size of input label
                    value={ETA}
                    onChange={e => setETA(e)}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    autoOk={true}
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid style={{ paddingTop: "30px" }} item xs={12}>
                <span
                  style={{
                    fontSize: "1.15rem",
                    paddingRight: "3px",
                  }}
                >
                  Notes
              </span>
                <FormControl fullWidth>
                  <TextField
                    multiline
                    name="notes"
                    variant="outlined"
                    minRows={5}
                    onChange={(e) => setNote(e.target.value)}
                    style={{
                      wordWrap: "break-word",
                      fontSize: "1.15rem",
                      float: "left",
                      overflowY: "auto",
                      width: "100%",
                    }}
                    value={note}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </React.Fragment>
          {
            updateErr && (
              <div style={{ fontSize: "1.15rem", marginTop: "8px", color: "#f44336" }}>
                {updateErr}
              </div>
            )
          }
          <div style={{ display: "flex", "width": "100%" }}>
            <span
              style={{ marginTop: "19px", cursor: "pointer", color: "#FF7F01" }}
              onClick={() => {
                if (isLoading) return;
                props.history.goBack();
              }}
            >
              <BackIcon />
              <span style={{ verticalAlign: "6px", fontSize: "1.1rem" }}>
                Back
            </span>
            </span>
            {isLoading ? (
              <CircularProgress size={26} style={{ marginLeft: "auto", marginTop: "20px" }} />
            ) : (
                <Button
                  style={{ marginLeft: "auto", marginTop: "10px" }}
                  variant="contained"
                  color="secondary"
                  size="large"
                  onClick={handleAccept}
                >
                  Submit
                </Button>
              )}
          </div>
        </Paper>
      </Grid>
    ) : (<Loader isSmall={props.isSmall} />)
  );
}
