import React, { useState, useEffect, useContext } from "react";
import {
  Grid, Paper, Typography, Button, CircularProgress, FormControlLabel,
  Checkbox
} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { ArrowBackIos as BackIcon, Clear, Add } from "@material-ui/icons";
import { API } from "aws-amplify";

import { checkRate, checkQuantity } from "../../libs/Errors";

import moment from 'moment';
import currency from "currency.js"

// components
import Loader from "../../components/Loader/Loader";

// libs
import { querystring } from "../../libs/querystring"
import { userContext } from "../../context/User";

// styles
import useStyles from "./styles";

export default function Update(props) {
  const classes = useStyles();
  const user = useContext(userContext);

  //state
  const [ticket, setTicket] = useState(null);
  const [customer, setCustomer] = useState({
    rates: { "standard": 0.00 }
  });
  const [customerNum, setCustomerNum] = useState("");

  // ticket to be submitted
  const [items, setItems] = useState([]);
  const [notes, setNotes] = useState([]);
  const [status, setStatus] = useState('Open');
  const [note, setNote] = useState('');
  const [ETA, setETA] = useState(null);

  // ticket to be created
  const [newRate, setNewRate] = useState("");
  const [newPrice, setNewPrice] = useState(0);
  const [newQuantity, setNewQuantity] = useState(0);
  const [newInside, setNewInside] = useState(false);


  const [statusErr, setStatusErr] = useState(false);
  const [updateErr, setUpdateErr] = useState('');
  const [ETAErr, setETAErr] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (!user) return;

    // get ticket id from url
    const ticket_id = querystring('ticket_id');
    const params = { queryStringParameters: { user_id: user.user_id } }

    // fetch ticket from ddb
    API.get('api', 'tickets/' + ticket_id).then(resp => {
      setTicket(resp);
      setItems(resp.items.map(item => ({ ...item })));
      setStatus(resp.status);
      setNotes(resp.notes);
      setETA(resp.eta ? new Date(resp.eta) : null);
      setCustomerNum(resp.customer_number);
      API.get("api", "customers/" + resp.customer_number, params).then((resp) => {
        setCustomer(resp);
      })
    }).catch(() => console.log('here')); // TODO: handle error
  }, [user]);


  function addItem() {
    // check to see if you need add to a previous entry
    let match = items.findIndex(item => item.rate === newRate && item.inside === newInside)
    if (match !== -1) {
      let temp = [...items];
      temp[match].quantity = parseInt(temp[match].quantity) + parseInt(newQuantity)
      return temp;
    }
    else {
      return [
        ...items,
        {
          rate: newRate,
          price: newPrice,
          quantity: newQuantity,
          inside: newInside
        }
      ]
    }
  };

  function summarizeItemChanges(original, updated) {
    let changes = "";

    updated.forEach((newItem) => {
      // find any items with the same rate and location
      let match = original.find((oldItem) => {
        return oldItem.rate === newItem.rate && oldItem.inside === newItem.inside;
      })

      // if there aren't any, it is a new item
      if (!match) {
        changes += `Item added: ${newItem.quantity}x ${newItem.rate}(${newItem.inside ? "inside" : "outside"})\n`
      } else { // otherwise the quantity was changed
        if (match.quantity !== newItem.quantity) {
          changes += `Item quantity changed: ${newItem.rate} from ${match.quantity} to ${newItem.quantity} (${newItem.inside ? "inside" : "outside"})\n`
        }
      }
    })

    // check for deleted items
    original.forEach((oldItem) => {
      let match = updated.find((newItem) => {
        return oldItem.rate === newItem.rate && oldItem.inside === newItem.inside;
      })
      if (!match) {
        changes += `Item removed: ${oldItem.quantity}x ${oldItem.rate}(${oldItem.inside ? "inside" : "outside"})\n`
      }
    })

    return changes;
  }

  async function handleUpdate() {
    // change price and quantity strings to decimal and int respectively
    let updatedItems = [...items];

    // if the new item form isn't empty, add it to the list of items
    if (!(checkRate(newPrice) || checkQuantity(newQuantity))) {
      updatedItems = addItem();
    }

    updatedItems = updatedItems.map((item) => (
      {
        ...item,
        "price": currency(item.price).value,
        "quantity": parseInt(item.quantity),
      }
    ))
    let oldItems = ticket.items.map((item) => (
      {
        ...item,
        "price": currency(item.price).value,
        "quantity": parseInt(item.quantity),
      }
    ))

    // reset form errors
    setStatusErr(false);
    setETAErr(false);
    setUpdateErr('');

    let updates = "";
    let updated = {
      "status": status,
      "date": new Date(Date.now()).toISOString(),
      "items": updatedItems
    };

    // get quantity changes
    updates += summarizeItemChanges(oldItems, updatedItems);

    // can't change status pending -> open
    if (ticket.status === 'Pending' && status === 'Open') {
      setStatusErr(true);
      setUpdateErr('Cannot change status from "Pending" to "Open".');
      return;
    }

    if (ticket.status !== status) {
      updates += "Status changed to " + status + ". ";
    }

    // validate ETA
    if (ETA && ETA.toString() === 'Invalid Date') {
      setETAErr(true);
      return;
    }
    if (ETA && ticket.eta !== ETA.toISOString()) {
      updates += "ETA changed to " + moment(ETA).format('MM-DD-YYYY') + ". ";
    } else if (!ETA && ticket.eta) {
      updates += "ETA removed. ";
    }

    updated.eta = ETA;

    // note
    if (!updates && !note) {
      setUpdateErr('No changes to submit.');
      setIsLoading(false);
      return;
    }

    const noteArr = notes;
    const date = new Date();
    noteArr.push({ [date.toISOString() + user.role]: updates + note });
    updated.notes = noteArr;

    // update ticket
    API.post("api", "update_ticket/" + ticket.ticket_id, { body: updated }).then(() => {
      if (status === 'Closed' && user.role === 'hauler')
        props.history.push('/create_invoice?store=' + ticket.store)
      else
        props.history.push('/tickets')
    });
  }

  return (
    ticket && user ? (
      <Grid container className={classes.container}>
        <Paper classes={{ root: classes.paperRoot }}>
          <React.Fragment>

            <Typography variant="h3" gutterBottom>
              Update Pickup Request
          </Typography>

            <Grid container spacing={2} style={{ justifyContent: "space-between", marginTop: '10px' }}>

              <React.Fragment>
                <Grid item xs={3} style={{ fontSize: "1.15rem" }}>
                  <TextField
                    required
                    label="Acct#"
                    disabled
                    value={customerNum}
                    onChange={e => setCustomerNum(e.target.value)}
                    inputProps={{ style: { fontSize: '1.15rem' } }} // font size of input text
                    InputLabelProps={{ style: { fontSize: '1.15rem' } }} // font size of input label
                  />
                </Grid>
              </React.Fragment>

              <Grid item xs={8} sm={3}>
                <FormControl style={{ marginTop: "3px" }} className={classes.formControl} error={statusErr}>
                  <InputLabel id="demo-simple-select-error-label">Status</InputLabel>
                  <Select
                    value={status}
                    disabled={user.role !== "admin"}
                    onChange={e => {
                      setStatus(e.target.value);
                    }}
                  >
                    <MenuItem value={'Open'}>Open</MenuItem>
                    <MenuItem value={'Pending'}>Pending</MenuItem>
                    <MenuItem value={'Closed'}>Closed</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item style={{ paddingTop: "30px" }} xs={12}> {/*+ Items label */}
                <Typography style={{ fontSize: "1.15rem" }}>Items</Typography>
              </Grid> {/*- Items label */}

              <Grid container id={"current-items"} > {/*+ Current Items */}
                {
                  items.map((item, index) => {
                    return (
                      <Grid item container> {/*+ Item row */}

                        <Grid item xs={1}> {/*+ Delete Button */}
                          <Button
                            style={{
                              maxWidth: "20px",
                              maxHeight: "20px",
                              minHeight: "20px",
                              minWidth: "20px",
                              marginTop: "10px"
                            }}
                            variant="outlined"
                            disabled={user.role === "hauler"}
                            onClick={() => {
                              setItems(items.filter((_, i) => i !== index));
                            }}
                            startIcon={<Clear style={{ marginLeft: "10px" }} />}
                            size="small"
                          />
                        </Grid> {/*- Delete Button */}

                        <Grid item xs={3}>
                          <TextField
                            value={item.rate}
                            disabled
                            error={item.rate === ""}
                            onChange={(e) => {
                              setItems(items.map((item, i) => {
                                if (i === index) {
                                  return { ...item, "rate": e.target.value };
                                }
                                return item;
                              }))
                            }}
                            style={{ marginRight: "20px", marginTop: "5px" }} />
                        </Grid>

                        <Grid item xs={2}>
                          <TextField
                            value={item.price}
                            disabled
                            error={checkRate(item.price) || item.price === ""}
                            onChange={(e) => {
                              setItems(items.map((item, i) => {
                                if (i === index) {
                                  return { ...item, "price": e.target.value };
                                }
                                return item;
                              }))
                            }}
                            style={{ marginRight: "20px", marginTop: "5px" }} />
                        </Grid >

                        <Grid item xs={2}>
                          <TextField
                            value={item.quantity}
                            error={checkQuantity(item.quantity)}
                            disabled={user.role === "hauler"}
                            onChange={(e) => {
                              setItems(items.map((item, i) => {
                                return i === index ? (
                                  { ...item, "quantity": e.target.value }
                                ) : item;
                              }))
                            }}
                            style={{ marginRight: "20px", marginTop: "5px" }} />
                        </Grid >

                        <Grid item xs={1}>
                          <FormControl style={{ marginBottom: "5px" }}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={item.inside}
                                  onChange={(e) => {
                                    let match = items.findIndex((origItem) => {
                                      return origItem.rate === item.rate && origItem.inside === e.target.checked;
                                    })
                                    if (match !== -1) {
                                      let temp = [...items];
                                      temp[match].quantity += item.quantity;
                                      temp = temp.filter((_, i) => i !== index);
                                      setItems(
                                        temp,
                                      )
                                    } else {
                                      setItems(items.map((item, i) => {
                                        return i === index ? (
                                          { ...item, "inside": e.target.checked }
                                        ) : item;
                                      }))
                                    }
                                  }}
                                />}
                              label="Inside" />
                          </FormControl>
                        </Grid>

                      </Grid>
                    )
                  })
                }
              </Grid>
              {user.role !== "hauler" && (
                <Grid container id={"new-item"} style={{ paddingTop: "15px" }} > {/*+ New Item */}

                  <Grid item container spacing={2} >

                    <Grid item xs={1}>
                      <Button
                        style={{
                          maxWidth: "20px",
                          maxHeight: "20px",
                          minHeight: "20px",
                          minWidth: "20px",
                          marginTop: "25px",
                        }}
                        disabled={newRate === "" || checkRate(newPrice) || checkQuantity(newQuantity)}
                        variant="outlined"
                        onClick={() => {
                          setItems(addItem());
                          setNewRate("");
                          setNewPrice(0);
                          setNewQuantity(0);
                        }}
                        startIcon={<Add style={{ marginLeft: "10px" }} />}
                        size="small"
                      />
                    </Grid>

                    <Grid item xs={4}>
                      {
                        !customer.rates.hasOwnProperty(newRate) && newRate !== "" ? (
                          <TextField
                            value={newRate === "Add Rate" ? "" : newRate}
                            helperText={"New Rate Name"}
                            error={newRate === ""}
                            onChange={(e) => {
                              if (customer.rates.hasOwnProperty(e.target.value)) {
                                setNewPrice(customer.rates[e.target.value])
                              }
                              setNewRate(e.target.value);
                            }
                            }
                            style={{ marginRight: "20px", marginTop: "15px" }} />) :
                          <FormControl style={{ width: "100%" }}>
                            <InputLabel>Rate</InputLabel>
                            <Select
                              style={{ width: "100%" }}
                              error={newRate === ""}
                              value={newRate}
                              onChange={(e) => {
                                setNewRate(e.target.value)
                                setNewPrice(customer.rates[e.target.value] ? customer.rates[e.target.value] : "0.00")
                              }}
                            >
                              {
                                Object.entries({ ...customer.rates, "Add Rate": "0.00" }).map(([key, value]) => {
                                  return (
                                    <MenuItem value={key}>{key}</MenuItem>
                                  );
                                })
                              }
                            </Select>
                          </FormControl>
                      }
                    </Grid>

                    <Grid item xs={2}>
                      <TextField
                        required
                        error={checkRate(newPrice)}
                        label="Price"
                        disabled={customer.rates.hasOwnProperty(newRate)}
                        value={newPrice}
                        onChange={(e) => setNewPrice(e.target.value)}
                      />
                    </Grid >

                    <Grid item xs={2}>
                      <TextField
                        required
                        error={checkQuantity(newQuantity)}
                        label="Qty."
                        value={newQuantity}
                        onChange={(e) => setNewQuantity(e.target.value)}
                      />
                    </Grid >

                    <Grid item xs={1}>
                      <FormControl style={{ marginTop: "15px" }}>
                        <FormControlLabel control={<Checkbox onChange={(e) => setNewInside(Boolean(e.target.checked))} checked={(newInside)} />} label="Inside" />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>)}
              <Grid item xs={10} sm={5}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    error={ETAErr}
                    disableToolbar
                    variant="inline"
                    format="MM-dd-yyyy"
                    label="ETA"
                    inputProps={{ style: { fontSize: '1.15rem' } }} // font size of input text
                    InputLabelProps={{ style: { fontSize: '1.15rem' } }} // font size of input label
                    value={ETA}
                    onChange={e => setETA(e)}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    autoOk={true}
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid style={{ paddingTop: "30px" }} item xs={12}>
                <span
                  style={{
                    fontSize: "1.15rem",
                    paddingRight: "3px",
                  }}
                >
                  Notes
              </span>
                <FormControl fullWidth>
                  <TextField
                    multiline
                    name="notes"
                    variant="outlined"
                    minRows={5}
                    onChange={(e) => setNote(e.target.value)}
                    style={{
                      wordWrap: "break-word",
                      fontSize: "1.15rem",
                      float: "left",
                      overflowY: "auto",
                      width: "100%",
                    }}
                    value={note}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </React.Fragment>
          {
            updateErr && (
              <div style={{ fontSize: "1.15rem", marginTop: "8px", color: "#f44336" }}>
                {updateErr}
              </div>
            )
          }
          <div style={{ display: "flex", "width": "100%" }}>
            <span
              style={{ marginTop: "19px", cursor: "pointer", color: "#FF7F01" }}
              onClick={() => {
                if (isLoading) return;
                props.history.goBack();
              }}
            >
              <BackIcon />
              <span style={{ verticalAlign: "6px", fontSize: "1.1rem" }}>
                Back
            </span>
            </span>
            {isLoading ? (
              <CircularProgress size={26} style={{ marginLeft: "auto", marginTop: "20px" }} />
            ) : (
                <Button
                  style={{ marginLeft: "auto", marginTop: "10px" }}
                  variant="contained"
                  color="secondary"
                  size="large"
                  onClick={handleUpdate}
                >
                  Submit
                </Button>
              )}
          </div>
        </Paper>
      </Grid>
    ) : (<Loader isSmall={props.isSmall} />)
  );
}
