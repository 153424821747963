/***************************************************/
// Customer.js
//
//	A form to display information for a single customer.
//	Can be set to edit/create/view modes
/***************************************************/
import React, { useEffect, useState, useContext } from "react";

import { querystring } from "../../libs/querystring";
import { usersContext } from "../../context/Users";
import { customersContext } from "../../context/Customers";
import { userContext } from "../../context/User";

//mui
import {
  Grid,
  Paper,
  Typography,
  Button,
} from "@material-ui/core";
//import { ArrowBackIos as BackIcon } from "@material-ui/icons";

import TextRow from "../../components/Generic/TextRow";
import AccountList from "../../components/User/AccountList";

import moment from "moment";
import { API } from "aws-amplify";


// styles
import useStyles from "./styles";

export default function User(props) {
  const classes = useStyles();
  const user = useContext(userContext);
  const users = useContext(usersContext);
  const customers = useContext(customersContext);

  //state
  const [targetUser, setTargetUser] = useState({
    created_at: "",
    email: "",
    notifications: false,
    phone: "",
    role: ""
  });

  const [accounts, setAccounts] = useState(null);

  // if mode or data changes, populate customer data if necessary
  useEffect(() => {
    if (!(customers && users && user) || user.role !== "admin") return;
    console.log(customers);

    const user_id = querystring('user_id');
    const params = { queryStringParameters: { user_id: user.user_id } }

    if (!users[user_id]) {
      console.log(`user not found:${user_id}`)
      return;
    } else {
      setTargetUser(users[user_id]);
    }

    API.get('api', 'users/get_subscriptions/' + user_id, params).then(resp => {
      setAccounts(resp.map(sub => sub.customer_id));
    }).catch(() => console.log('here')); // TODO: handle error

  }, [users, user, customers]);

  return user && users && customers && (
    <Grid container className={classes.container}>
      <Paper classes={{ root: classes.paperRoot }}>
        <React.Fragment>
          <Typography variant="h3" gutterBottom>
            User Info
          </Typography>

          <Grid container item xs={12} spacing={2}>

            <Grid container item xs={12}>
              {targetUser.name && <TextRow value={targetUser.name} label="Name" />}
              {targetUser.email && <TextRow value={targetUser.email} label="Email" />}
              {targetUser.phone && <TextRow value={targetUser.phone} label="Phone" />}
              {targetUser.role && <TextRow value={targetUser.role} onChange={()=>{}} label="Role" />}
              {targetUser.created_at && <TextRow value={moment(targetUser.created_at).format("MM-DD-YYYY")} label="Created At" />}
            </Grid>

            {accounts && <AccountList accounts={accounts} customers={customers} />}


					<Button
						style={{ marginTop: "10px", marginRight: "10px" }}
						variant="contained"
						color="secondary"
						size="large"
						className={classes.backButton}
						onClick={() => { props.history.push('/users') }}
					>
						Back
					</Button>

          <Button
            style={{ backgroundColor: "red", marginLeft: "auto", marginTop: "10px" }}
            variant="contained"
            size="large"
            className={classes.backButton}
            onClick={(e) => {
              e.stopPropagation(); // stop the onRowClick event
              let name = targetUser.name ? targetUser.name : targetUser.user_id

              if (window.confirm(`Are you sure you would like to delete user : ${name}`)) {
                let params = { queryStringParameters: { user_id: user.user_id } };
                API.del('api', 'delete_user/' + targetUser.user_id, params).then(_ => {
                  props.history.push("/users");
                })
              }
            }}
          >
            Delete
              </Button>
          <Button
            style={{ marginLeft: "auto", marginTop: "10px" }}
            variant="contained"
            color="primary"
            size="large"
            className={classes.backButton}
            onClick={() => {
                props.history.push(`/update_user?user_id=${targetUser.user_id}`);
            }}
          >
            Update
          </Button>
          </Grid>
        </React.Fragment>
      </Paper>
    </Grid>
  );
}
