import React, { useState, useEffect, useContext } from "react";
import { API } from "aws-amplify";

// components
import Loader from "../../components/Loader/Loader";


import { userContext } from "../../context/User";
import { usersContext } from "../../context/Users";
import { customersContext } from "../../context/Customers";

// mui
import {
  Grid, Paper, Typography, Button,
} from "@material-ui/core";

import UserForm from '../../components/User/UserForm';

// styles
import useStyles from "./styles";

export default function Create(props) {
  const classes = useStyles();

  const user = useContext(userContext);
  const users = useContext(usersContext);
  const customers = useContext(customersContext);

  //state
  const [newUser, setNewUser] = useState({
    role: "",
    accounts: [],
    email: "",
  });


  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!user || !customers) return;
    setIsLoading(false);
  }, [user, customers]);

  function handleCreate() {
    let errorMessage = "";

    if (newUser.role === "customer" && newUser.accounts.length === 0) {
      errorMessage += "Customer must have at least one associated account\n"
    }
    if (newUser.role === "") errorMessage += "You must select a role for user.\n";
    if (newUser.email === "") errorMessage += "You must enter an email for user.\n";
    if (errorMessage !== "") {
      alert(errorMessage)
      return;
    }

    API.post("api", "create_user/", {
      body: newUser,
      queryStringParameters: { user_id: user.user_id }
    }).then((resp) => {
      users[resp.user_id] = newUser;
      props.history.push("/users")
    });
  }

  return isLoading ? <Loader /> : (user && user.role === "admin") && (
    <Grid container className={classes.container}>
      <Paper classes={{ root: classes.paperRoot }}>
        <Typography variant="h3" gutterBottom>
          Create New User
          </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <UserForm newUser={newUser} setNewUser={setNewUser} customers={customers} />
          </Grid>

          <Grid item container xs={12} style={{ justifyContent: 'space-evenly' }}>
            <Button
              style={{ marginTop: "10px", marginRight: "10px" }}
              variant="contained"
              color="primary"
              size="large"
              className={classes.backButton}
              onClick={() => { props.history.push('/users') }}
            >
              Back
					</Button>
            <Button
              style={{ width: "40%", marginTop: "10px", marginRight: "10px" }}
              variant="contained"
              color="secondary"
              size="large"
              className={classes.backButton}
              onClick={handleCreate}
            >
              Submit
					</Button>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
};
